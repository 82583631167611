import config from '../config/config';
import { FetchWrapper, FetchWrapperResponse, FetchWrapperTypedResponse } from '../fetch/FetchWrapper';
import LogUtil from '../helpers/LogUtil';

import AsyncStorageHelper from './AsyncStorageHelper';
import SSOAuthDetails from './SSOAuthDetails';

export type WorkspaceAccess = {
	id: string;
	name: string;
	domain: string;
	authMethods: string[];
	parent?: WorkspaceAccessParent;
};

export type WorkspaceAccessParent = Pick<WorkspaceAccess, 'id' | 'name' | 'domain'>;

export type BefareTokenResponse = {
	token: string;
	workspaces: WorkspaceAccess[];
	redirectPath?: string;
};

export type BefareRefreshTokenResponse = BefareTokenResponse & {
	error?: string;
	naughty?: boolean;
};

export class AuthFailedError implements Error {
	name: string = 'AuthFailedError';
	message: string;

	constructor(message: string = '') {
		this.message = message;
		Object.setPrototypeOf(this, AuthFailedError.prototype);
	}
}

/**
 * AuthManager
 */
export default class AuthManager {
	static async magicLinkVerification(token: string): Promise<FetchWrapperResponse> {
		const host = config.hosts.auth;
		const url = `//${host}/v1/native/magic-link`;
		const response = await FetchWrapper.post(url, {
			body: JSON.stringify({
				token,
			}),
			headers: {
				'application-token': 'bef-web',
			},
		});

		if (response.statusCode !== 200) {
			// do nothing
			await AsyncStorageHelper.removeBefareJWTCache();
		} else {
			// response.data either is the jwt or it's an object with .token where you can find jwt (temp transition period from jan 26th  2022)
			AuthManager.processLoginResponse(response.data);
		}

		return response;
	}

	static async refreshToken(workspaceId?: string): Promise<FetchWrapperTypedResponse<BefareRefreshTokenResponse>> {
		const host = config.hosts.auth;
		const url = `//${host}/v1/native/refresh${workspaceId ? '/' + workspaceId : ''}`;
		const response = await FetchWrapper.post(url, {
			headers: {
				'application-token': 'bef-web',
			},
		});
		if (response.statusCode === 200) {
			AuthManager.processLoginResponse(response.data);
		} else {
			await AsyncStorageHelper.removeBefareJWTCache();
		}

		return response;
	}

	private static processLoginResponse(loginResponseData: any) {
		let jwt = loginResponseData;
		if (loginResponseData.token) {
			jwt = loginResponseData.token;

			// Do something about the workspace to let the user select?
			if (loginResponseData?.redirectPath) {
				AsyncStorageHelper.setCache(`@BefWeb:login:redirect`, loginResponseData.redirectPath);
			}

			if (loginResponseData?.workspaces) {
				AsyncStorageHelper.setCache(`@BefWeb:login:workspaces`, loginResponseData.workspaces);
			}
		}

		AsyncStorageHelper.cacheBefareJWT(jwt);
	}

	/**
	 * @returns {Promise<Project[]>} chats
	 */
	static async phoneAuth(countryCode: string = '47', phoneNumber: string): Promise<any> {
		try {
			const host = config.hosts.auth;
			const url = `//${host}/v1/native/phone`;
			console.log(`\n\n\nCalling ${url}`);
			const response = await fetch(url, {
				method: 'POST',
				credentials: 'include',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json',
					'application-token': 'bef-web',
				},
				body: JSON.stringify({
					countryCode,
					phoneNumber,
				}),
			});

			if (response.status !== 200) {
				await AsyncStorageHelper.removeBefareJWTCache();
				throw new Error(`Phone Auth Failed. ${response.status} for ${url}`);
			}

			const status = await response.json();

			return status;
		} catch (error: any) {
			throw new AuthFailedError(error);
		}
	}

	/**
	 * @returns {Promise<Project[]>} chats
	 */
	static async emailAuth(email: string) {
		try {
			const host = config.hosts.auth;
			const url = `//${host}/v2/email-ml`;
			const response = await FetchWrapper.post(url, {
				headers: {
					'application-token': 'bef-web',
				},
				body: JSON.stringify({
					email,
				}),
			});

			if (response.statusCode !== 200) {
				await AsyncStorageHelper.removeBefareJWTCache();
				throw new Error(`Email Auth Failed. ${response.statusCode} for ${url}`);
			}
		} catch (error: any) {
			throw new AuthFailedError(error);
		}
	}

	/**
	 * @returns {Promise<Project[]>} chats
	 */
	static async phoneAuthVerify(
		countryCode: string = '47',
		phoneNumber: string,
		verificationCode: string,
		workspaceId?: string
	): Promise<FetchWrapperResponse> {
		const host = config.hosts.auth;
		const url = `//${host}/v1/native/phone-verify`;

		const response = await FetchWrapper.post(url, {
			credentials: 'include',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
				'application-token': 'bef-web',
			},
			body: JSON.stringify({
				verificationCode,
				workspaceId,
				countryCode,
				phoneNumber,
			}),
		});

		if (response.statusCode !== 200) {
			await AsyncStorageHelper.removeBefareJWTCache();
		} else {
			AuthManager.processLoginResponse(response.data);
		}

		return response;
	}

	/**
	 * @param {any} ssoAuthDetails
	 * @returns {boolean} whether login was successful or not
	 */
	static async befareAuthenticate(authDetails: any): Promise<boolean> {
		if (authDetails.accessToken) {
			try {
				const workspace = await AsyncStorageHelper.getCachedWorkspace();
				if (!workspace) {
					return false;
				}

				const ssoAuthDetails = new SSOAuthDetails(authDetails, workspace);
				await AsyncStorageHelper.cacheSSOAuthDetails(ssoAuthDetails);

				const befareJWT = await AuthManager.requestBefareJWT(ssoAuthDetails);
				await AsyncStorageHelper.cacheBefareJWT(befareJWT);

				return true;
			} catch (err) {
				LogUtil.error(err);
				return false;
			}
		}

		return false;
	}

	/**
	 * @param  {any} authDetails
	 */
	static async requestBefareJWT(authDetails: any) {
		try {
			const url = `https://${config.hosts.auth}/azure-sso/authenticate`;
			console.log(`requesting befare JWT from ${url}`);
			const response = await fetch(url, {
				method: 'POST',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json',
					'Application-Token': '81549300',
				},
				body: JSON.stringify(authDetails),
			});

			console.log(response);

			const befareJWT = await response.json();

			console.log(`Got befare JWT`, befareJWT);

			AsyncStorageHelper.cacheBefareJWT(befareJWT);

			return befareJWT;
		} catch (error) {
			console.warn(error);
		}
	}
}
